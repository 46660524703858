import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Accent from "../../../../../components/accent"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import PaperStack from "../../../../../components/paper-stack"
import Poem from "../../../../../components/poem"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      as="form"
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/jahreszeiten/01-jahreszeitengedicht/zusammenfassung" />
      }
    >
      <Seo title="Das Gedicht sprechen" />
      <Box>
        <Stack>
          <Heading as="h1" level={2}>
            Das Gedicht sprechen
          </Heading>
          <Paragraph>
            Nun bist du an der Reihe! Wie würdest du das Gedicht selbst
            vortragen?
          </Paragraph>
          <Paragraph>
            Beim Lesen von Gedichten macht man am Ende jeder Zeile eine kurze
            Pause. Damit kann man auch Spannung erzeugen, denn die Zuhörer
            warten bereits gespannt auf die Fortsetzung.
          </Paragraph>
          <Paragraph>
            Überlege dir, welche Stellen du besonders hervorheben möchtest, und
            achte darauf, dass du die Wörter richtig betonst. Die betonten
            Silben haben wir mit einem <Accent>Akzent</Accent> markiert. Das
            hilft dir, um zu erkennen, welche Wörter und Silben du beim Vorlesen
            etwas deutlicher hervorheben kannst. Du kannst die betonten Silben
            beim Vorlesen auch auf dem Tisch mitklopfen. Probiere es einmal aus!
          </Paragraph>
          <Note variant="task">
            Lese das Gedicht laut vor und klopfe die betonten Silben dabei mit.
          </Note>

          <PaperStack>
            <Poem>
              <p>
                Der <Accent>Früh</Accent>ling
              </p>
              <br />
              <p>
                Wenn <Accent>aus</Accent> der <Accent>Tie</Accent>fe{" "}
                <Accent>kommt</Accent> der <Accent>Früh</Accent>ling{" "}
                <Accent>in</Accent> das <Accent>Le</Accent>ben,
              </p>
              <p>
                Es <Accent>wun</Accent>dert <Accent>sich</Accent> der{" "}
                <Accent>Mensch</Accent>, und <Accent>neu</Accent>e{" "}
                <Accent>Wor</Accent>te <Accent>stre</Accent>ben
              </p>
              <p>
                Aus <Accent>Geis</Accent>tig<Accent>keit</Accent>, die{" "}
                <Accent>Freu</Accent>de <Accent>keh</Accent>ret{" "}
                <Accent>wie</Accent>der
              </p>
              <p>
                Und <Accent>fest</Accent>lich <Accent>ma</Accent>chen{" "}
                <Accent>sich</Accent> Ge<Accent>sang</Accent> und{" "}
                <Accent>Lie</Accent>der.
              </p>
              <br />
              <p>
                Das <Accent>Le</Accent>ben <Accent>fin</Accent>det{" "}
                <Accent>sich</Accent> aus <Accent>Har</Accent>mo
                <Accent>nie</Accent> der <Accent>Zei</Accent>ten,
              </p>
              <p>
                Daß <Accent>im</Accent>mer<Accent>dar</Accent> den{" "}
                <Accent>Sinn</Accent> Na<Accent>tur</Accent> und{" "}
                <Accent>Geist</Accent> ge<Accent>lei</Accent>ten,
              </p>
              <p>
                Und <Accent>die</Accent> Voll<Accent>kom</Accent>men
                <Accent>heit</Accent> ist <Accent>Ei</Accent>nes{" "}
                <Accent>in</Accent> dem <Accent>Gei</Accent>ste,
              </p>
              <p>
                So <Accent>fin</Accent>det <Accent>vie</Accent>les{" "}
                <Accent>sich</Accent>, und <Accent>aus</Accent> Na
                <Accent>tur</Accent> das <Accent>Mei</Accent>ste.
              </p>
              <br />
              <p>d. 24 Mai 1758.</p>
              <br />
              <p>
                Mit <Accent>Un</Accent>ter<Accent>thä</Accent>nig
                <Accent>keit</Accent>
              </p>
              <p>
                <Accent>Scar</Accent>da<Accent>nel</Accent>li.
              </p>
            </Poem>
          </PaperStack>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
